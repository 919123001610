import React, { Component } from "react";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { Button } from "@instructure/ui-buttons";
import { ApplyTheme } from "@instructure/ui-themeable";
import { darken } from "@instructure/ui-themeable/lib/color";
import IconArrowOpenEnd from "@instructure/ui-icons/lib/IconArrowOpenEndSolid";
import IconArrowOpenStart from "@instructure/ui-icons/lib/IconArrowOpenStartSolid";
import IconCheckDark from "@instructure/ui-icons/lib/IconCheckDarkSolid";
import IconX from "@instructure/ui-icons/lib/IconXSolid";

import Layout from "../../../components/layout";
import QuestionHeader from "../../../components/question-header";
import SEO from "../../../components/seo";
import styles from "./final-scenario.module.css";
import { setCurrentModule, setCurrentStep } from "../../../state/actions";
import { purple } from "../../../shared/colors";
import { func, shape, object } from "prop-types";

const ANSWERS = [
  {
    id: 1,
    text: "You most likely would not need permission because he is older.",
    correct: false,
    feedback:
      "Even though he would be more mature as a 16-year-old, he is still a minor in foster care and the physical risk requires caseworker permission."
  },
  {
    id: 2,
    text:
      "You most likely would need permission because he does not have enough experience.",
    correct: false,
    feedback:
      "His experience is important to keep in mind, but that doesn’t mean that the risk has been eliminated from the activity. Four-wheeling has inherent risk that always requires caseworker permission."
  },
  {
    id: 3,
    text:
      "You most likely would not need permission because he has been four-wheeling before.",
    correct: false,
    feedback:
      "Even though he has had previous experience with four-wheeling, his experience doesn’t guarantee that he is free from risk. Because of inherent risk, four-wheeling always requires caseworker permission."
  },
  {
    id: 4,
    text:
      "You most likely would need permission because the activity involves risk.",
    correct: true,
    feedback:
      "Four wheeling is an activity that involves more risk than other activities. He might have experience, but that does not mean that the risk has been eliminated."
  }
];

class FinalScenario extends Component {
  state = {
    hasSubmitted: false,
    hasSeenFeedback: false,
    lastClicked: null
  };

  static propTypes = {
    handleNavigate: func,
    goToStep: func,
    data: shape({
      file: shape({
        childImageSharp: shape({
          fixed: object
        })
      })
    })
  };

  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(1);
    this.props.goToStep(6);
  }

  onSubmit = () => {
    if (!this.state.hasSubmitted) {
      this.setState({
        hasSubmitted: true
      });
    } else {
      this.props.goToStep(7, "/modules/normalcy/summary");
    }
  };

  goBack = () => {
    this.props.goToStep(6, "/modules/normalcy/ensure-safety");
  };

  getVariantFromState(id) {
    const { correct } = ANSWERS.find(x => x.id === id);
    if (this.state.lastClicked === id) {
      if (correct) {
        return "success";
      } else {
        return "danger";
      }
    }
  }

  getIconFromState(id) {
    const { correct } = ANSWERS.find(x => x.id === id);
    if (this.state.lastClicked === id) {
      if (correct) {
        return IconCheckDark;
      } else {
        return IconX;
      }
    }
  }

  render() {
    return (
      <Layout isModule>
        <SEO
          title="Module - Normalcy"
          keywords={["utah foster care", "foster care"]}
        />
        <ApplyTheme
          theme={{
            [Button.theme]: {
              defaultBackground: purple,
              defaultHoverBackground: darken(purple, 10),
              defaultActiveBackground: darken(purple, 10),
              defaultColor: "white"
            }
          }}
        >
          <div
            className={`${styles.moduleGridContainer} ${
              this.state.hasSubmitted ? styles.hasSubmitted : ""
            }`}
          >
            <div className={styles.titleArea}>
              <b>Here's a final scenario.</b> Use what you've learned in this
              module to answer the question.
            </div>
            <div className={styles.scenario}>
              You and your family want to go four wheeling, a typical activity
              that you all do every summer. You have been fostering Liam for 9
              months. He is a typical 11-year-old boy and he wants to come
              along. He has never been 4 wheeling before, but he thinks that he
              will be fine. You went through the 6 items of the reasonable and
              prudent parenting standard to determine if he could go.
              Eventually, you decided that you would need to ask the case worker
              for permission due to the risk and his ability to participate in
              the activity from lack of experience. What would happen if Liam
              was 5 years older and has been 4 wheeling before?
            </div>
            <div className={styles.questionArea}>
              <QuestionHeader
                className={styles.questionHeader}
                icon={
                  <span
                    className={styles.emoji}
                    role="img"
                    aria-label="Thinking Face"
                  >
                    🤔
                  </span>
                }
              >
                <span>
                  What would happen if Liam was 5 years older and has been 4
                  wheeling before?
                </span>
              </QuestionHeader>
              {ANSWERS.map(a => (
                <div key={a.id} className={styles.answerContainer}>
                  <Button
                    size="medium"
                    variant={this.getVariantFromState(a.id)}
                    icon={this.getIconFromState(a.id)}
                    onClick={() =>
                      this.setState({
                        lastClicked: a.id,
                        hasSubmitted: true
                      })
                    }
                    fluidWidth
                  >
                    {a.text}
                  </Button>
                </div>
              ))}
            </div>
            {this.state.hasSubmitted && (
              <div className={styles.feedbackArea}>
                {ANSWERS.find(x => this.state.lastClicked === x.id).feedback}
              </div>
            )}
            <div className={styles.backButtonArea}>
              <Button
                onClick={this.goBack}
                variant="success"
                icon={IconArrowOpenStart}
              />
            </div>

            <div className={styles.forwardButtonArea}>
              <Button
                onClick={this.onSubmit}
                variant="success"
                icon={IconArrowOpenEnd}
                disabled={
                  !this.state.hasSubmitted ||
                  !(ANSWERS.find(x => this.state.lastClicked === x.id) || {})
                    .correct
                }
              />
            </div>
          </div>
        </ApplyTheme>
      </Layout>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, navigateTo) {
      dispatch(setCurrentStep(stepId));
      if (navigateTo) {
        navigate(navigateTo);
      }
    }
  };
};

export default connect(
  stateToProps,
  dispatchToProps
)(FinalScenario);
